<template>
<editor
  v-model="code"
  @init="editorInit"
  :lang="lang"
  theme="dawn"
  :options="options"
></editor>
</template>
<script>
import editor from "vue2-ace-editor";
export default {
  props:['code','lang'],
  components:{editor},
  data(){
    return {
      options:{
        maxLines: 30,
        minLines: 2,
        readOnly: true,
        printMargin:false,
      }
    }
  },
  methods:{
    editorInit() {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/csharp");
      require("brace/mode/json"); //language
      require("brace/theme/dawn");
    },
  }
}
</script>